


















import { Component, Vue } from 'vue-property-decorator';

import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import CartCheckout from '@/app/modules/cart/components/CartOrderCheckout.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import OrderSummaryWidget from '@/app/modules/cart/components/partials/OrderSummaryWidget.vue';
import Cart from '@/app/lib/cart/Cart';
import { redirect } from '@/shared/lib/support/router/RouterUtils';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Order from '@/app/lib/order/Order';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';

@Component({
  components: {
    OrderSummaryWidget,
    GridCol,
    GridRow,
    CartCheckout,
    Button,
    MainHeader,
  },
})
export default class CartCheckoutView extends Vue {
  /**
   * Data
   */
  private order: OnlineOrderCoreModel | null = Order.model;

  /**
   * Getters
   */
  private get totals(): AmountsFormattedInterface {
    return Order.totalsFormatted;
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    this.checkOrderIfNotEmpty();
  }

  /**
   * Methods
   */
  private checkOrderIfNotEmpty() {
    if (Cart.isEmpty()) {
      redirect('/cart');
    }
  }
}
